/* a template for the GrowthInitiativepage layout,
 * which is used both for the index and
 * pomp pages. See src/pages/index.js. */

// dependencies.
import React from 'react'
// components.
import DescriptionSection from 'components/pages/promo/DescriptionSection'
import HeaderSection from 'components/pages/promo/HeaderSection'
import { Header, Footer } from 'src/components/layout'

// Styles & Images
import 'static/growth-initiative/scss/styles.scss'

// Main component:
const GrowthInitiativePage = ({ images, pageContext }) => {
  // get page data.
  const { colorsOverride, headerSection, howtoSection, descriptionSection } = pageContext.data

  const headerSectionImages = {
    exodusImage: images.headerLogoExodus.childImageSharp.gatsbyImageData,
    promoImage: images.headerLogoPromo.childImageSharp.gatsbyImageData,
  }

  // return content.
  return (
    <div className="x__promo-page">
      <Header />

      <main className="x">
        {headerSection && (
          <HeaderSection
            colors={colorsOverride}
            data={{ ...headerSection, howto: { ...howtoSection } }}
            images={headerSectionImages}
          />
        )}
        {descriptionSection && (
          <DescriptionSection data={{ ...descriptionSection, howto: { ...howtoSection } }} />
        )}
      </main>

      <Footer backgroundColor="#0D061C" />
    </div>
  )
}

export default GrowthInitiativePage
