// dependencies.
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
// components.
import HowToSection from 'src/components/pages/promo/HowToSection'
// utils.
import { useDimensions } from 'js/utils/hooks'

// Styles & Images:
import 'src/components/pages/promo/scss/HeaderSection.scss'

// Partials.
const BrandChrome = ({ colors = ['#A91AED', '#6A009D'] }) => (
  <div
    className="x__promo-page__header__brand-chrome"
    style={{ background: `linear-gradient(180deg, ${colors[0]} 0%, ${colors[1]} 100%)` }}
  />
)

const HeaderSection = ({ colors, data, images }) => {
  const { h1, h2, howto } = data

  const { isMobile } = useDimensions()

  return (
    <header className="x__promo-page__header">
      <BrandChrome colors={colors} />

      <GatsbyImage
        className="x__promo-page__header__logo x__promo-page__header__logo--exodus"
        image={images.exodusImage}
        alt="Exodus"
        objectFit="contain"
      />
      <GatsbyImage
        className="x__promo-page__header__logo x__promo-page__header__logo--promo"
        image={images.promoImage}
        alt={h1 || 'Promo brand logo'}
        objectFit="contain"
      />

      <div className="x__promo-page__header__content">
        <div>
          {h1 && (
            <h1 className="x__promo-page__header__heading">
              <span dangerouslySetInnerHTML={{ __html: h1 }} />
            </h1>
          )}
          {h2 && (
            <h2
              className="x__promo-page__header__subheading"
              dangerouslySetInnerHTML={{ __html: h2 }}
            />
          )}
        </div>

        {howto && <HowToSection data={howto} isCarousel={isMobile} />}
      </div>
    </header>
  )
}

export default HeaderSection
