// dependencies.
import React from 'react'
// components.
import HowToSection from 'src/components/pages/promo/HowToSection'

// Styles & Images:
import 'src/components/pages/promo/scss/DescriptionSection.scss'

const DescriptionSection = ({ data }) => {
  const { intro, h2, video, howto } = data

  return (
    <section className="x__promo-page__description">
      <div className="x__promo-page__description__content">
        {intro && (
          <p className="x__promo-page__description__supheading">
            <span dangerouslySetInnerHTML={{ __html: intro }} />
          </p>
        )}
        {h2 && (
          <h2
            className="x__promo-page__description__heading"
            dangerouslySetInnerHTML={{ __html: h2 }}
          />
        )}

        {video && video.url && (
          <>
            <h3
              className="x__promo-page__description__subheading"
              dangerouslySetInnerHTML={{ __html: video.title }}
            />
            <div className="x__promo-page__description__video">
              <iframe
                src={video.url}
                allow="autoplay;fullscreen;picture-in-picture"
                allowfullscreen
                frameborder="0"
              />
            </div>
          </>
        )}

        {howto && <HowToSection data={howto} />}
      </div>
    </section>
  )
}

export default DescriptionSection
