// dependencies.
import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import classNames from 'classnames'
// components.
import Button from 'src/components/button'

// Styles & Images:
import 'src/components/pages/promo/scss/HowToSection.scss'

const Item = ({ index, title, description, size = 'normal' }) => (
  <div
    className={classNames('x__promo-page__how-to__item', {
      'x__promo-page__how-to__item--small': size === 'small',
    })}
  >
    {index && <span className="x__item__number">{index}</span>}
    {title && <h4 className="x__item__title">{title}</h4>}
    {description && <p className="x__item__description">{description}</p>}
  </div>
)

const HowToSection = ({ data, isCarousel }) => {
  const { h3, steps, cta } = data

  const carouselRef = useRef(null)
  const timeoutRef = useRef(null)

  const [activeIndex, setActiveIndex] = useState(0)
  const [touchStartX, setTouchStartX] = useState(0)
  const [touchEndX, setTouchEndX] = useState(0)

  const carouselSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 350,
    slidesToShow: 1,
    lazyLoad: 'ondemand',
    slidesToScroll: 1,
    afterChange: (current) => setActiveIndex(current),
  }

  // swipeable / touch events.
  const handleTouchStart = (e) => {
    setTouchStartX(e.changedTouches[0].screenX)
  }

  const handleTouchEnd = (e) => {
    setTouchEndX(e.changedTouches[0].screenX)

    if (!touchStartX || !touchEndX) return

    const MIN_SWIPE_DISTANCE = 24
    const swipeDistance = touchStartX - touchEndX

    if (swipeDistance > MIN_SWIPE_DISTANCE) carouselRef.current.slickNext()
    if (swipeDistance < -MIN_SWIPE_DISTANCE) carouselRef.current.slickPrev()
  }

  // auto-play.
  const resetTimeout = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
  }

  useEffect(() => {
    if (!isCarousel) {
      return
    }

    resetTimeout()
    timeoutRef.current = setTimeout(() => carouselRef.current.slickNext(), 5000)

    return () => resetTimeout()
  }, [activeIndex])

  if (!steps && steps.length === 0) return null

  // Return carousel.
  return (
    <div>
      {isCarousel ? (
        <div className="x__promo-page__how-to__carousel">
          <div
            className="x__promo-page__how-to__carousel__items"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <Slider {...carouselSettings} ref={carouselRef}>
              {steps.map(({ title, description }, index) => (
                <Item key={index + 1} title={title} description={description} size="small" />
              ))}
            </Slider>
          </div>
        </div>
      ) : (
        <div className="x__promo-page__how-to">
          <h3 className="x__promo-page__how-to__heading" dangerouslySetInnerHTML={{ __html: h3 }} />
          <div className="x__promo-page__how-to__steps">
            {steps.map(({ title, description }, index) => (
              <Item key={index + 1} index={index + 1} title={title} description={description} />
            ))}
          </div>
        </div>
      )}
      <div className="x__promo-page__how-to__actions">
        <Button copy={cta.copy} to={cta.path} size="largest" />
      </div>
    </div>
  )
}

export default HowToSection
